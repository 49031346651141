<p-toast [style]="{marginTop: '60px'}" key="APP_TOAST_KEY"></p-toast>
<p-confirmDialog #cd key="APP_CONFIRM_KEY">
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 bg-orange-100 border-round">
            <div class="border-circle bg-red-100 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-question text-5x1"></i>
            </div>
            <span class="font-bold text-2x1 block mb-2 mt-4">{{message.header}}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <p-button [ngClass]="'w-8rem'" [styleClass]="'w-8rem'" severity="info" label="Yes" (click)="cd.accept()"></p-button>
                <p-button [outlined]="true" [ngClass]="'w-8rem'" [styleClass]="'w-8rem'" severity="danger" label="Cancel" (click)="cd.reject()"></p-button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>
<p-confirmPopup key="APP_CONFIRM_POPUP_KEY" icon="pi pi-exclamation-triangle"></p-confirmPopup>


<div class="layout-container" [ngClass]="containerClass">
    <app-topbar></app-topbar>
    <div class="layout-content-wrapper flex flex-column w-full">
        <div class="layout-content">
             <div class="grid flex-column grid-nogutter">
                <router-outlet></router-outlet>
             </div>
        </div>
         <app-footer></app-footer>
    </div>
   
    <div class="layout-mask"></div>
</div>
 


