<div class="col-12 md:col-8 md:col-offset-2">
	<div class="card">
		<p-carousel [ngClass]="'w-full'" [value]="fileItems" [numVisible]="2" [numScroll]="2" [circular]="true" [responsiveOptions]="responsiveOptions" autoplayInterval="3000">
			<ng-template let-fileItem pTemplate="item">
				<div class="border-1 surface-border border-round m-2 p-3">
					<img [src]="fileItem.src" [alt]="fileItem.name" class="w-full border-round"/>
				</div>
			</ng-template>
		</p-carousel>
	</div>
</div>