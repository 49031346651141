import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as am from '../models/account-models';
import { ApiResponse } from '../models/app-models';

@Injectable()
export class AccountService {
	
	constructor(private http: HttpClient) {}
	
	performLogin(user: am.LoginUser): Observable<ApiResponse<am.AccessToken>> {
		return this.http.post<ApiResponse<am.AccessToken>>(`${am.ACCOUNT_BASE_URL}/login`, user);
	}
}