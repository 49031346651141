import {Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http'
import { AuthenticationService } from './services/authentication-service';
import { AppEventService } from './services/app-event-service';
import { AppMessageService } from './services/app-message-service';
import { PrimeIcons } from 'primeng/api';
import { TopNavMenuConfiguration } from './models/menu-models';
import { ApiResponse } from './models/app-models';
import * as mc from './utils/app-menu-constants'
import { TopBarMenuEvent } from './models/event-models';
import { ADD_ACTION, DELETE_ACTION, HTTP_OK, HTTP_BAD_REQUEST } from './utils/app-constants';
import { MenuItemCreator, BarMenuItem, ROOT_FONT_SIZE, ROOT_ICON_SIZE } from './models/menu-models';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
    topbarConfig: TopNavMenuConfiguration;
    model: any[] = [];
    
    constructor(private router: Router, private authService: AuthenticationService, private eventService: AppEventService,private msgService: AppMessageService){}

    ngOnInit() {
        const shopMenu: BarMenuItem = {
            label: mc.SHOP_MENU_LABEL,
            icon: PrimeIcons.SHOPPING_CART,
            iconSize: ROOT_ICON_SIZE,
            fontSize: ROOT_FONT_SIZE,
            roles: [],
            isNoneRole: true,
            url: 'https://sinoesoft.com'
        }
        
        //MenuItemCreator.createMenuItemWithCommand([], true, mc.SHOP_MENU_LABEL, PrimeIcons.SHOPPING_CART, () => {
        //    
        //});
        this.topbarConfig = new TopNavMenuConfiguration([shopMenu, ...mc.TOP_BAR_MENU_ITEMS]);
        this.model = this.topbarConfig.getTopMenuItems([]);
        this.authService.accessController.subscribe((roles: string[]) => {
            this.model = this.topbarConfig.getTopMenuItems(roles);
        });
        this.eventService.topbarMenuEventListener.subscribe((event: TopBarMenuEvent) => {
            this.processMenuEvent(event);
        });
    }
    private processMenuEvent(event: TopBarMenuEvent): void {
        switch (event.action) {
            case ADD_ACTION:
                this.topbarConfig.addRootBarItem(event.rootItem);
                break;
            case DELETE_ACTION:
                this.topbarConfig.removeBRootarItem(event.menuId);
                break;   
        }
    }
            
    private handleError(errorResp: HttpErrorResponse, header: string = 'Academic Year'): void {
        this.eventService.blockUIListener.emit(false);
        this.msgService.showError(header, errorResp.message);
    }
}
