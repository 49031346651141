import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, UrlTree }  from '@angular/router';
import { AuthenticationService } from "../services/authentication-service";

@Injectable()
export class LoginGuard implements CanActivate {
	
	constructor(private router: Router, private authService: AuthenticationService) {}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
        if (this.authService.isLogin()) {
			return true;
		}
		this.router.navigate(['/login']);
		return false;
    }
}