export const APP_TOAST_KEY = 'APP_TOAST_KEY';
export const APP_CONFIRM_KEY = 'APP_CONFIRM_KEY';
export const APP_CONFIRM_POPUP_KEY = 'APP_CONFIRM_POPUP_KEY';
export const INFO_MSG = 'Info';
export const SUCCESS_MSG =  'success';
export const WARNING_MSG = 'warn';
export const ERROR_MSG = 'error';
export const DEFAULT_MSG_LIFE = 5000;
export const DEFAULT_ERROR_MSG_LIFE = 15000;

