import { AppMessageService } from './app-message-service';
import { AuthenticationService } from './authentication-service';
import { AppEventService } from './app-event-service';
import { AccountService } from './account-service';
import { LoginGuard } from '../utils/login-guard';
import { DashboardService } from './dashboard-service';
import { LayoutService } from './app.layout.service';
import { MenuService } from './app.menu.service';

export const APP_SERVICES: any[] = [
	AppMessageService,
	AuthenticationService,
	AppEventService,
	AccountService,
	LoginGuard,
	DashboardService,
	LayoutService,
	MenuService
	
];