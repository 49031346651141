import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication-service';
import { AUTHORIZATION_HEADER, METHOD_DELETE, METHOD_POST, METHOD_PUT, USER_ID_HEADER, USER_NAME_HEADER } from '../models/account-models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (req.method === METHOD_POST || req.method === METHOD_PUT || req.method === METHOD_DELETE) {
        const authToken = this.authService.getOauthToken();
        if (authToken) {
          const authReq = req.clone({
            headers: req.headers.set(AUTHORIZATION_HEADER, `Bearer ${authToken}`)
                                .set(USER_ID_HEADER, this.authService.getAuthenticatedUser().username)
                                .set(USER_NAME_HEADER, this.authService.getAuthenticatedUser().name)
            });
          return next.handle(authReq);
        }
        return next.handle(req);
      }
      return next.handle(req);
    }

}

