import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard-service';
import { FileItem } from '../../models/app-models';
import { TEST_IMAGES } from '../../utils/app-constants';

@Component({
    selector: 'app-home',
    templateUrl: './dashboard.html'
    
})
export class DashboardComponent implements OnInit {
    fileItems!: FileItem[];
    responsiveOptions: any;
    
    constructor(private dashboardService: DashboardService) {}
    
    ngOnInit(): void {
        this.fileItems = TEST_IMAGES;
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];
        //this.dashboardService.getImages().subscribe({
         //   next: (records: FileItem[]) => {
        //        this.fileItems = records;
        //    }
        //});
    }
}