import { Injectable, EventEmitter } from '@angular/core';
import { TOKEN_PARAM, AccessToken, AuthenticatedUser } from '../models/account-models';
import { AppUtils } from '../utils/app-utils';

@Injectable()
export class AuthenticationService {
    
    private user!: AuthenticatedUser | null;
    
    private accessToken!: AccessToken | null;
     
    accessController: EventEmitter<string[]> = new EventEmitter();
     
	constructor() {}
	 
	initAccess(token: AccessToken): void {
        this.accessToken = token;
        this.user = new AuthenticatedUser(token);
        this.accessController.emit(token.roles);
    }
    
    getOauthToken(): string | undefined {
        return this.accessToken?.token;
    }
	getAuthenticatedUser(): AuthenticatedUser | null {
        return this.user;
    }
    logout(): void {
        this.accessToken = null;
        this.user = null;
        this.accessController.emit([]);
        AppUtils.removeValue(TOKEN_PARAM);
    }
    isLogin(): boolean | null {
      return this.accessToken && (this.accessToken.token !== undefined || this.accessToken.token !== null);
    }
}