<div class="footer-bg px-4 py-8 md:px-6 lg:px-8 layout-footer">
    <div class="grid">
        <div class="col-12 md:col-3 flex flex-wrap justify-content-start md:mb-0 mb-3">
            <img src="assets/images/logo.png" alt="Image" height="65px">
        </div>
        <div class="col-12 md:col-3">
            <div class="text-white text-lg mb-4 flex flex-wrap" style="max-width: 290px">HomeWorld</div>
            <div class="text-white mb-3"><i class="pi pi-phone border-round p-1 mr-2"></i>Local: {{localPhone}}</div>
            <div class="text-white mb-3"><i class="pi pi-phone border-round p-1 mr-2"></i>Internationl: {{internalPhone}}</div>
            <div class="text-white mb-3"><i class="pi pi-inbox border-round p-1 mr-2"></i><a [href]="emailRef" style="color: white;">{{contactEmail}}</a></div>
        </div>
        <div class="col-12 md:col-3 text-white">
            <div class="text-white font-bold line-height-3 mb-3">Company</div>
            <a class="line-height-3 block cursor-pointer mb-2" (click)="oab.toggle($event)">About Us</a>
            <a class="line-height-3 block cursor-pointer mb-2" (click)="omt.toggle($event)">Mission</a>
            <!--<a class="line-height-3 block cursor-pointer mb-2">News</a>
            <a class="line-height-3 block cursor-pointer mb-2">Investor Relations</a>
            <a class="line-height-3 block cursor-pointer mb-2">Careers</a>
            <a class="line-height-3 block cursor-pointer">Media Kit</a>-->
        </div>
        <!--<div class="col-12 md:col-3 text-gray-200">
            <div class="text-white font-bold line-height-3 mb-3">Resourses</div>
            <a class="line-height-3 block cursor-pointer mb-2">Get Started</a>
            <a class="line-height-3 block cursor-pointer mb-2">Learn</a>
            <a class="line-height-3 block cursor-pointer">Case Studies</a>
        </div>-->
    </div>
</div>

<p-overlayPanel #oab styleClass="w-full md:w-6 border-round surface-50 p-3">
    <ng-template pTemplate="content">
        <div class="flex flex-column">
            <h6>About Us</h6>
            <p>
               Home World Building Supplies is a building supply store, fulfilling a need that will continue to exist into the future - the need for a store that provides essential building supplies in one location. We exist to be a single sourcing solution to local contractors, developers, Do-It-Yourselfers (DIYs) and homeowners.  Home World consist of several departments covering all stages in the construction and building process. 
            </p>
        </div>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #omt styleClass="w-full md:w-6 border-round surface-50 p-3">
    <ng-template pTemplate="content">
        <div class="flex flex-column">
            <h6>Mission</h6>
            <p>
               To be a single source for all construction and home improvement needs while providing value-added customer service in every local community that we serve. 
            </p>
        </div>
    </ng-template>
</p-overlayPanel>