export interface AccountHolder {
    id?: string;
    username?: string;
    roles?: string[];
    firstName?: string;
    lastName?: string;
    email?: string;
    isAccountActive?: boolean;
    accountType?: string;
    lastLogin?: string;
    tempPasswordCopy?: string;
    isResetPassword?: boolean;
}
export interface AccessToken {
    token: string;
    roles: string[];
    username: string;
    fullName: string;
    isResetPassword: boolean;
    accountType?: string;
    lastLogin?: string;
}


export interface LoginUser {
    username: string;
    password: string;
    oldPassword?: string;
    accountType?: string;
}
export class AuthenticatedUser {
    name: string;
    username: string;
    roles: string[];
    accountType: string | undefined;
    lastLogin: string | undefined;
    
    constructor(token: AccessToken) {
        this.name = token?.fullName;
        this.username = token?.username;
        this.roles = token?.roles;
        this.accountType = token?.accountType;
        this.lastLogin = token?.lastLogin;
    }
    
}
export const AUTHORIZATION_HEADER = 'Authorization';
export const USER_NAME_HEADER = 'SNX-USERNAME';
export const USER_ID_HEADER = 'SNX-USERID';
export const METHOD_POST = 'POST';
export const METHOD_DELETE = 'DELETE';
export const METHOD_PUT = 'PUT';
export const ACCESS_TOKEN_PARAM = 'atj1';
export const USER_PARAM = 'UPX';
export const TOKEN_PARAM = 'TKZ';




export const ACCOUNT_ACTIVE = 'active';
export const ACCOUNT_INACTIVE = 'inactive';
export const ACCOUNT_BASE_URL = '/account';
export const USER_NAME_PARAM = 'UNP';
export const RESET_PARAMS = 'RCP';
export const LOG_ENTRY_BASE_URL = '/log';