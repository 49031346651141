import { Injectable } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DEFAULT_MSG_LIFE, APP_CONFIRM_KEY, APP_TOAST_KEY, INFO_MSG, SUCCESS_MSG, WARNING_MSG, ERROR_MSG,
        APP_CONFIRM_POPUP_KEY, DEFAULT_ERROR_MSG_LIFE } from '../utils/app-message-constants';

@Injectable()
export class AppMessageService {
    constructor(private msgService: MessageService, private confirmService: ConfirmationService) {}

    showInfo(header: string, msg: string): void {
        this.showMessage(INFO_MSG, header, msg);
    }
    showSuccess(header: string, msg: string): void {
        this.showMessage(SUCCESS_MSG, header, msg);
    }
    showWarning(header: string, msg: string): void {
        this.showMessage(WARNING_MSG, header, msg);
    }
    showError(header: string, msg: string): void {
        this.showMessage(ERROR_MSG, header, msg, DEFAULT_ERROR_MSG_LIFE);
    }
    showConfirmation(headerStr: string, msg: string, acceptFunc: () => void, rejectFunc?: () => void): void {
        if (rejectFunc) {
            this.confirmService.confirm({
                key: APP_CONFIRM_KEY,
                header: headerStr,
                message: msg,
                accept: acceptFunc,
                reject: rejectFunc

            });
        } else {
            this.confirmService.confirm({
                key: APP_CONFIRM_KEY,
                header: headerStr,
                message: msg,
                accept: acceptFunc,
            });
        }
    }
    showConfirmationPopup(headerStr: string, msg: string, owner: any, acceptFunc: () => void, rejectFunc?: () => void): void {
        if (rejectFunc) {
            this.confirmService.confirm({
                key: APP_CONFIRM_POPUP_KEY,
                header: headerStr,
                message: msg,
                target: owner,
                accept: acceptFunc,
                reject: rejectFunc

            });
        } else {
            this.confirmService.confirm({
                key: APP_CONFIRM_KEY,
                header: headerStr,
                message: msg,
                accept: acceptFunc,
            });
        }
    }

    private showMessage(msgType: string, header: string, msg: string, msgLife: number = DEFAULT_MSG_LIFE): void {
        this.msgService.clear();
        this.msgService.add({
            key: APP_TOAST_KEY,
            severity: msgType,
            summary: header,
            detail: msg,
            life: msgLife
        });
    }
}
