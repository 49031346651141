import { Injectable, EventEmitter } from '@angular/core';
import { TopBarMenuEvent} from '../models/event-models';

@Injectable()
export class AppEventService {
	
	blockUIListener: EventEmitter<boolean> = new EventEmitter();
	
	topbarMenuEventListener: EventEmitter<TopBarMenuEvent> = new EventEmitter();
	
	constructor() {}
	
}