<div class="col-12 md:col-6 md:col-offset-3">
	<div class="card">
		<div class="card-header gap-3">
			<div class="card-title">
				<h6>Image Loader</h6>
                <p class="subtitle">
                    Select images to upload.
                 </p>
            </div>
        </div>
        <div class="flex-column">
			<p-fileUpload  name="imagefiles"  accept="" [auto]="true" [maxFileSize]="" [customUpload]="true" chooseLabel="Select Image"
			         (onUpload)="doOnUpload($event)" (onRemove)="doOnRemove($event)"/>
			 
		</div>
	</div>
</div>