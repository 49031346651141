import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileItem } from '../models/app-models';
import { DASHBOARD_BASE_URL } from '../utils/app-constants';

@Injectable()
export class DashboardService {
	
	constructor(private http: HttpClient) {}
	
	getImages(): Observable<FileItem[]> {
		return this.http.get<FileItem[]>(`${DASHBOARD_BASE_URL}`);
	}
}