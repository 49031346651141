import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from './services/app.layout.service';
import { AppEventService } from './services/app-event-service';
import { AppSidebarComponent } from './app.sidebar.component';
import { AuthenticationService } from './services/authentication-service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopbarComponent implements OnInit {
    appBusy: boolean;
    shopUrl: string;
    
    @ViewChild('menubutton') menuButton!: ElementRef;
    @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
    activeItem!: number;
    constructor(public layoutService: LayoutService, public el: ElementRef, public authService: AuthenticationService, private router: Router, private eventService: AppEventService) {}
    
    ngOnInit(): void {
        this.shopUrl = 'https://homeworldbuilding.com/';
        this.eventService.blockUIListener.subscribe((flag: boolean) => this.appBusy = flag);
    }
    onMenuButtonClick() {
        this.layoutService.onMenuToggle();
    }

    onSidebarButtonClick() {
        this.layoutService.showSidebar();
    }

    onConfigButtonClick() {
        this.layoutService.showConfigSidebar();
    }
    logout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
    resetPassword(): void {
        this.router.navigate(['/resetpassword']);
    }
}
