import { PrimeIcons } from 'primeng/api';
import { BarMenuItem, MenuItemCreator } from '../models/menu-models';


export const SHOP_MENU_LABEL = 'Shop';


//const SHOP_MENU: BarMenuItem = MenuItemCreator.createMenuItemWithLink([],true, SHOP_MENU_LABEL, PrimeIcons.SHOPPING_CART, ['/shoponline']);
//SHOP_MENU.isNoneRole = true;

export const TOP_BAR_MENU_ITEMS: BarMenuItem[] = [
	//SHOP_MENU
];


