import { FileItem } from "../models/app-models";

export const ADD_ACTION = 'ADD';
export const EDIT_ACTION = 'EDIT';
export const DELETE_ACTION = 'DELETE';
export const CANCEL_ACTION = 'CANCEL';

export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_NO_CONTENT = 204;

export const USER_ID_PARAM = 'UIP';

export const API_DATE_FORMAT = 'MMddyyyy';
export const DISPLAY_DATE_FORMAT = 'MMM dd, yyyy';
export const DISPLAY_TIMESTAMP_FORMAT = 'MMM d, yyyy h:mm a';

export const BATCH_FILE_PARAM = 'batchfileparam';
export const META_DATA_PARAM = 'metadataparam';

export const DASHBOARD_BASE_URL = 'dashboard';

export const TEST_IMAGES: FileItem[] = [
	{id: '1', src: 'assets/images/store/photo4.png', name: 'image_1'},
	{id: '2', src: 'assets/images/store/photo3.png', name: 'image_2'},
	{id: '3', src: 'assets/images/store/photo2.png', name: 'image_3'},
	{id: '4', src: 'assets/images/store/photo1.png', name: 'image_4'}
];

