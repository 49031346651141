<div class="layout-topbar">
    <div class="topbar-start">
        <button #menubutton type="button" class="topbar-menubutton p-link p-trigger transition-duration-300" (click)="onMenuButtonClick()">
            <i class="pi pi-bars"></i>
        </button>
    </div>
    <div class="layout-topbar-menu-section">
        <app-sidebar></app-sidebar>
    </div>
    <div class="topbar-end">
        <ul class="topbar-menu">
            <li class="topbar-item" *ngIf="authService.isLogin()">
                <a pStyleClass="@next" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple class="cursor-pointer">
                   <p-avatar icon="pi pi-user" styleClass="mr-2" size="xlarge" shape="circle"></p-avatar>
                </a>
                <ul [class]="'topbar-menu active-topbar-menu p-4 w-15rem   z-5 ng-hidden'">
                    <li role="menuitem" class="m-0 mb-3">
                        <a href="#" (click)="logout()" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-sign-out mr-2"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                    <li role="menuitem" class="m-0 mb-3">
                        <a href="#" (click)="resetPassword()" class="flex align-items-center hover:text-primary-500 transition-duration-200" pStyleClass="@grandparent" enterFromClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden" leaveActiveClass="px-fadeout">
                            <i class="pi pi-fw pi-sync mr-2"></i>
                            <span>Reset Password</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
<p-progressBar mode="indeterminate" [ngStyle]="{'width': '100%'}" *ngIf="appBusy"></p-progressBar>
