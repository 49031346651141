import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-image-loader',
	templateUrl: './image-loader.html'
})
export class ImageLoaderComponent implements OnInit {
	
	private files!: File[];
	
	constructor() {}
	
	ngOnInit(): void {
		this.files = [];
    
    }
    doOnUpload(event: any): void {
		
	}
	doOnRemove(event: any): void {
		
	}
}