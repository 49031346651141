import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-user-login',
	templateUrl: './user-login.html'
})
export class UserLoginComponent implements OnInit {
	
	constructor() {}
	
	ngOnInit(): void {
    
    }
}