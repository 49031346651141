import { USER_ID_PARAM } from "./app-constants";

export class AppUtils {
	static getUserId(): string | null {
		return AppUtils.getValue(USER_ID_PARAM);
	}
	static setValue(attr: string, value: string): void {
        if (value) {
            const codedVal = btoa(value);
            sessionStorage.setItem(attr, codedVal);
        }
    }
    static setObjectAsValue(attr: string, value: any, local?: boolean): void {
        const jsonStr = JSON.stringify(value);
        this.setValue(attr, jsonStr);
    }
    static getValue(attr: string): string | null {
        const value =  sessionStorage.getItem(attr);
        if (value) {
            return atob(value);
        } else {
            return null;
        }

    }
    static getValueAsObject(attr: string): any {
        const val = AppUtils.getValue(attr);
        if (val) {
            return JSON.parse(val);
        } else {
            return null;
        }
    }
    static setRawValue(attr: string, value: string): void {
        sessionStorage.setItem(attr, value);
    }
    static getRawValue(attr: string): string | null {
        const value =  sessionStorage.getItem(attr);
        if (value) {
            return value;
        } else {
            return null;
        }
    }
    static removeValue(attr: string): void {
        sessionStorage.removeItem(attr);
    }
}