import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel'; 
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { StyleClassModule } from 'primeng/styleclass';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';

export const APP_PRIMENG_MODULES: any[] = [
	ToastModule,
	ConfirmDialogModule,
	ConfirmPopupModule,
	CardModule,
	BlockUIModule,
	ProgressSpinnerModule,
	StyleClassModule,
	CarouselModule,
	FileUploadModule,
	OverlayPanelModule,
	AvatarModule
];