import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard';
import { ImageLoaderComponent } from './components/image-loader/image-loader';
import { UserLoginComponent } from './components/user-login/user-login';
import { LoginGuard } from './utils/login-guard';


const routes: Routes = [
  {path: 'home', component: DashboardComponent},
  {path: 'imageloader', component: ImageLoaderComponent, canActivate: [LoginGuard]},
  {path: 'login', component: UserLoginComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
