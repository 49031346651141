<div class="layout-sidebar" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div class="sidebar-header">
        <a [routerLink]="['/']" class="app-logo cursor-pointer">
           <img class="logo-image" [src]="'assets/images/logo.png'" alt="logo" height="50px"/> 
         </a>
        <button class="layout-sidebar-anchor p-link" type="button" (click)="anchor()"></button>
    </div>

    <div #menuContainer  class="layout-menu-container">
        <app-menu></app-menu>
    </div>
</div>
