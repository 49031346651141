import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-footer',
	templateUrl: 'footer.html'
})
export class FooterComponent implements OnInit {
	contactEmail: string;
	emailRef: string;
	localPhone: string;
	internalPhone: string;
	tiktok: string;
	instagram: string;
	
	constructor() {}
	
	ngOnInit(): void {
        this.contactEmail = 'info@homeworldbuilding.com';
        this.emailRef=`mailto:${this.contactEmail}`;
        this.internalPhone = '+233205155676';
        this.localPhone = '0205155676';
        this.tiktok = '@homeworldgh';
        this.instagram = '@homeworldgh';
        
    }
}