import * as ld from "lodash";

export interface MenuChangeEvent {
    key: string;
    routeEvent?: boolean;
}

export const ROOT_ICON_SIZE = '1.05';
export const SUB_ICON_SIZE = '0.875';
export const ROOT_FONT_SIZE = '1.125';
export const SUB_FONT_SIZE = '1';

export interface BarMenuItem {
    label: string;
    icon: string;
    routerLink?: string[];
    items?: BarMenuItem[];
    iconSize: string;
    fontSize: string;
    roles?: string[];
    permitted?: boolean;
    state?: any
    id?: string;
    isNoneRole?: boolean;
    command?: (event: any) => void;
    url?: string;
};
export interface SessionMenuItem {
    session: string;
    routerLink: string[];
    label: string;
    index: number;
    
}

export class MenuItemCreator {
        
    static createMenuItemWithLink(roles: string[], isRoot: boolean, label: string, icon: string, routerLink: string[], items?: BarMenuItem[]): BarMenuItem {
        return this.createMenuItem(roles, isRoot, label, icon, items, routerLink);
    }
    static createMenuItemWithLinkAndState(roles: string[], isRoot: boolean, label: string, icon: string, routerLink: string[], state: any, items?: BarMenuItem[]): BarMenuItem {
        return this.createMenuItem(roles, isRoot, label, icon, items, routerLink, null, state);
    }
    static createMenuItemWithCommand(roles: string[], isRoot: boolean, label: string, icon: string,command: (event: any) => void, items?: BarMenuItem[]): BarMenuItem {
        return this.createMenuItem(roles, isRoot, label, icon, items, null, command);
    }
    static createMenuItem(roles: string[], isRoot: boolean, label: string, icon: string, items?: BarMenuItem[], routerLink?: string[], command?: (event: any) => void, state?: any): BarMenuItem {
        const menuitem: BarMenuItem = {
            label: label,
            icon: icon,
            iconSize: isRoot ? ROOT_ICON_SIZE : SUB_ICON_SIZE,
            fontSize: isRoot ? ROOT_FONT_SIZE : SUB_FONT_SIZE,
            roles: roles
        }
        if (routerLink) {
            menuitem.routerLink = routerLink;
        }
        if (items) {
            menuitem.items = items;
        }
        if (command) {
            menuitem.command = command;
        }
        if (state) {
            menuitem.state = state;
        }
        return menuitem;
    }
}
export class TopNavMenuConfiguration {
    topMenuItems: BarMenuItem[];
    
    constructor(topMenuItems: BarMenuItem[]) {
        this.topMenuItems = topMenuItems;
        
    }
    getTopMenuItems(roles: string[]): BarMenuItem[] {
        this.topMenuItems.forEach((item: BarMenuItem) => this.configureTopMenu(item, roles));
         return ld.cloneDeep(this.topMenuItems);
    }
    addRootBarItem(item: BarMenuItem): void {
        this.topMenuItems.push(item);
    }
    getRootBarItemByLabel(label: string): BarMenuItem {
        return this.topMenuItems.find((item: BarMenuItem) => item.label === label);
    }
    removeBRootarItem(id: string): void {
        const index = this.topMenuItems.findIndex((val: BarMenuItem) => val.id === id);
        if (index >=0) {
            this.topMenuItems.splice(index, 1);
        }
    }
    
    private configureTopMenu(topItem: BarMenuItem, roles: string[]): void {
        if (topItem.items && topItem.items.length > 0) {
            topItem.items.forEach((item: BarMenuItem) => this.configureTopMenu(item, roles))
        }
        const canDoRoles: string[] = topItem.roles.filter((val: string) => roles.find((role: string) => val === role) !== undefined);
        topItem.permitted =  topItem.isNoneRole ? true  :  canDoRoles && canDoRoles.length > 0;
        
    }
}

